<template>
  <button v-if="allowed" :type="type" :class="buttonClasses" :disabled="disabled">
    <slot />
  </button>
</template>

<script setup>
import { useAuthStore } from "~/stores/AuthStore";

const props = defineProps({
  htmlType: {
    type: String,
    default: "primary",
  },
  submit: {
    type: Boolean,
    default: false,
  },
  disabled: {},
  ignorePermission: {
    default: false,
  },
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['xs', 'sm', 'md', 'lg'].includes(value)
  }
});

const type = ref("button");
if (props.submit) type.value = "submit";

// Berechtigung überprüfen
const route = useRoute();
const authStore = useAuthStore();
const allowed = ref(true);
const buttonClasses = computed(() => {
  return [
    'btn',
    `btn-${props.htmlType}`,
    `btn-${props.size}`
  ];
});
if (route.meta.permission && !props.ignorePermission && !authStore.hasPermission([ route.meta.permission ])) allowed.value = false;
</script>
<style scoped>
.btn-custom-xs {
  padding: 0.1rem 0.3rem;
  font-size: 0.75rem;
  line-height: 1.2;
}
</style>